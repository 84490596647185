<template>
  <v-footer dark padless>
    <v-card flat tile class="secondary white--text text-center">
      

      <v-card-text class="white--text pt-0">
    All Rights Reserved. ©️ 2024 Perfect Manpower Consultants
      </v-card-text>
        <v-btn text class="white--text pt-0" :to="{name: 'TermsandConditions'}">
          Terms and Conditions
        </v-btn>

        <v-btn text class="white--text pt-0" :to="{name: 'Services'}">
          Services
        </v-btn>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        <strong> Design By :<a href='https://megworldtech.com'>Meg World Technologies </a></strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}
</style>

<script>
export default {
  data: () => ({
    icons: [
      {
        text: "mdi-facebook",
        link: "https://facebook.com/emtiazzahid/",
      },
      {
        text: "mdi-twitter",
        link: "https://linkedin.com/emtiaz_zahid/",
      },
      {
        text: "mdi-linkedin",
        link: "https://linkedin.com/emtiazzahid/",
      },
      {
        text: "mdi-instagram",
        link: "https://instagram.com/emtiazzahid/",
      },
    ],
  }),
};
</script>
